import { render, staticRenderFns } from "./cover.vue?vue&type=template&id=7f7b865e&scoped=true&"
import script from "./cover.vue?vue&type=script&lang=js&"
export * from "./cover.vue?vue&type=script&lang=js&"
import style0 from "./cover.vue?vue&type=style&index=0&id=7f7b865e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f7b865e",
  null
  
)

export default component.exports